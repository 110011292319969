<template>
  <div>
    <EtatActiviteEditor
      ref="CreateForm"
      :visible="etatActviteModalVisible"
      @cancel="closeModal"
      @close="closeModal"
      @create="handleCreate"
      :key="newComponentKey"
      :processing="saving"
      :etatActivite="selectedEtatActivite"
      :disableDateChangement="isEditorDateChangementDisabled"
      :dateInstallation="userDateInstallationText"
    />
    <a-row type="flex" justify="center" class="table_head">
      <a-col :span="18">
        <h1>Etat Activités</h1>
      </a-col>
      <a-col :span="18" class="mrb mrt">
        <a-button
          class="editable-add-btn"
          type="primary"
          @click="showModal"
          v-if="!processing"
          >Ajouter activité</a-button
        >
      </a-col>
      <a-col>
        <p><b>Veuillez saisir tout votre parcours d'activité professionnel (en incluant vos arrêts d'activité).</b></p>
      </a-col>
      <a-col :span="18">
        <EtatActivitesList
          :etat-activites="etatActivites"
          :processing="processing"
          @refresh_data="getUser"
          @delete_file="deleteEtatActiviteFile($event)"
          @delete="deleteEtatActivite($event)"
          @edit="editEtatActivite($event, etatActivite)"
        />
      </a-col>
    </a-row>
  </div>
</template>
<script>
  import EtatActivitesList from "@/components/common/EtatActivite";
  import EtatActiviteEditor from "@/components/common/EtatActiviteEditor";

  import {mapActions, mapGetters, mapState} from "vuex";

  export default {
    name: "EtatActiviteForm",
    components: {
      EtatActivitesList,
      EtatActiviteEditor,
    },
    data() {
      return {
        userId: null,
        processing: false,
        saving: false,
        newComponentKey: 0,
        selectedEtatActivite: null,
        etatActviteModalVisible: false,
        isEditorDateChangementDisabled: false,
      };
    },
    created() {
      this.fetchData();
  },
  computed: {
    ...mapState({
      user: (state) => state.adherent.adherent,
    }),
    ...mapGetters({
      etatActivites: "getUserEtatActivites",
    }),
    userDateInstallationText() {
      return this.user ? this.user.dt_autorisation : "";
    },
  },
  methods: {
    fetchData() {
      this.$store.cache.dispatch("fetchAdherent");
    },
    getDemandeAttestationComponentKey() {
      this.newComponentKey = this.getRandomInt();
    },
    handleCreate() {
      const form = this.$refs.CreateForm.form;
      let that = this;
      form.validateFields((err, values) => {
        if (err) {
          return;
        }
        let fields = form.getFieldsValue();
        fields["date_changement"].format("YYYY-MM-DD");
        let etatActivite = {
          ...fields,
          user_id: this.user.id,
          date_changement: this.formatDate(
            fields["date_changement"],
            "YYYY-MM-DD"
          ),
        };
        if (that.selectedEtatActivite) {
          this.updateEtatActvite({
            ...etatActivite,
            id: that.selectedEtatActivite.id,
          })  ;
          return;
        }
        this.addEtatActvite(etatActivite);
      });
    },
    resetEtatActiviteFormFields() {
      this.$refs["CreateForm"].form.resetFields();
    },
    showModal() {
      this.getDemandeAttestationComponentKey();
      this.etatActviteModalVisible = true;
    },
    closeModal() {
      this.isEditorDateChangementDisabled = false;
      this.resetEtatActiviteFormFields();
      this.clearSelectedEtatActivite();
      this.etatActviteModalVisible = false;
    },
    startProcessing() {
      this.processing = true;
    },
    stopProcessing() {
      this.processing = false;
    },
    startSaving() {
      this.saving = true;
    },
    stopSaving() {
      this.saving = false;
    },
    addEtatActvite(etatActivite) {
      this.startSaving();
      this.storeEtatActvite(etatActivite)
        .then(() => {
          this.closeModal();
          this.fetchUser();
        })
        .catch((er) => {
          if (er && er.response.data) {
            this.displayErrors(er);
          }
        })
        .finally(()=>  this.stopSaving());
    },
    updateEtatActvite(etatActivite) {
      this.startSaving();
      this.updateUserEtatActvite(etatActivite)
        .then(() => {
          this.closeModal();
          this.stopSaving();
          this.fetchUser();
        })
         .catch((er) => {
          if (er && er.response.data) {
            this.displayErrors(er);
          }
        })
       .finally(()=>  this.stopSaving());
    },
    deleteEtatActivite(id) {
      this.startProcessing();
      this.deleteUserEtatActvite(id)
        .then(() => this.fetchUser())
        .catch((er) => {
          this.stopProcessing();
          if (er && er.response.data) {
            this.displayErrors(er);
          }
        });
    },
    fetchUser(){
      this.startProcessing();
      this.getUser()
          .finally(() => this.stopProcessing());
    },
    deleteEtatActiviteFile(id) {
      this.startProcessing();
      this.deleteUserEtatActiviteFile(id)
         .then(() => this.fetchUser())
         .catch((er) => {
          this.stopProcessing();
          if (er && er.response.data) {
            this.displayErrors(er);
          }
        });
    },
    setEditorDateChangementDisabled(index) {
      this.isEditorDateChangementDisabled =
        this.etatActivites.length - 1 === index;
    },
    setSelectedEtatActivite(etatActivite) {
      this.selectedEtatActivite = etatActivite;
    },
     clearSelectedEtatActivite() {
      this.selectedEtatActivite = null;
    },
    editEtatActivite(data) {
      this.setSelectedEtatActivite(data.etatActivite);
      this.setEditorDateChangementDisabled(data.index);
      this.showModal();
    },
    ...mapActions({
      getUser: "fetchAdherent",
      deleteFile: "destroyAdminUserEtatActviteFile",
      storeEtatActvite: "storeUserEtatActivites",
      deleteUserEtatActvite: "destroyUserEtatActivite",
      deleteUserEtatActiviteFile: "destroyUserEtatActiviteFile",
      updateUserEtatActvite: "updateUserEtatActvite",
    }),
  },
};
</script>
<style scoped>
.table_head {
  margin-bottom: 5%;
}
.action_icons {
  margin: 4px;
  font-size: large;
}
.icons-list >>> .anticon {
  margin-right: 6px;
  font-size: 24px;
}
.pd-l {
  padding-left: 5%;
}
.mrb {
  margin-bottom: 2%;
}
.mrt {
  margin-bottom: 2%;
}
</style>
